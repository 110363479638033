@import 'components/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* BADGE */

.badge {
  font-weight: normal;
  background: $white;
  color: $secondary;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.6em .8em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .3rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &.badge-default {
    background: $default;
    color: $white;
  }
  &.badge-primary {
    background: $primary;
    color: $white;
  }
  &.badge-secondary {
    background: $secondary;
    color: $white;
  }
  &.badge-success {
    background: $success;
    color: $white;
  }
  &.badge-danger {
    background: $danger;
    color: $white;
  }
  &.badge-warning {
    background: $warning;
    color: $white;
  }
  &.badge-info {
    background: $info;
    color: $white;
  }
  &.badge-light {
    background: $light;
    color: $text;
  }
  &.badge-dark {
    background: $dark;
    color: $white;
  }
}

// dark theme
[data-kit-theme='dark'] {
  .badge {
    &.badge-light {
      background: $dark;
      color: $white;
    }
    &.badge-dark {
      background: $light;
      color: $text;
    }
  }
}
